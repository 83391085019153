import React from "react";
// import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation, Navigate, Outlet } from "react-router-dom";

import { CourseProvider } from "./context/CourseContext";

// pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Logout from "./pages/Logout";
import NotFound from "./pages/NotFound";
import Course from "./pages/Course";
import SubscriptionPreviousInvoices from "./pages/course/SubscriptionPreviousInvoices";
import SubscriptionInvoice from "./pages/course/SubscriptionInvoice";
import CourseHome from "./pages/course/CourseHome";

//ui
// import AnimatingPage from "./middleware/AnimatingPage";

//middlewares
import ProtectedRoute from "./middleware/ProtectedRoute";
import OnlyGuestRoute from "./middleware/OnlyGuestRoute";
import User from "./pages/User";
import UserHome from "./pages/user/UserHome";
import UserCourses from "./pages/user/UserCourses";
import Year from "./pages/Year";
// import { UserProvider } from "./context/UserContext";
import AdminLogin from "./pages/AdminLogin";
import AdminLogout from "./pages/AdminLogout";
import NotAdmin from "./middleware/NotAdmin";
import OnlyAdmin from "./middleware/OnlyAdmin";
import AdminPanel from "./pages/AdminPanel";
import ManualSubscription from "./pages/admin/ManualSubscription";
import { adminPath, isManualPayment } from "./services/defaultSettings";
import PaymentInfoPage from "./pages/course/PaymentInfoPage";
import CourseEditing from "./pages/admin/CourseEditing";
import SelectCourseForm from "./pages/admin/SelectCourseForm";
import CourseSectionEditing from "./pages/admin/CourseSectionEditing";
import ManualPaymentInfo from "./pages/admin/ManualPaymentInfo";
import VideoEditing from "./pages/admin/VideoEditing";
import Video from "./pages/course/Video";
import BookEditing from "./pages/admin/BookEditing";
import Book from "./pages/course/Book";
import ManualUnsubscription from "./pages/admin/ManualUnsubscription";
import Division from "./pages/admin/Division";
import UserProfile from "./pages/admin/UserProfile";
import UserProfileForm from "./pages/admin/UserProfileForm";
import UsersTable from "./pages/admin/UsersTable";
import CoursesTable from "./pages/admin/CoursesTable";
import SubscriptionsTable from "./pages/admin/SubscriptionsTable";
import InvoicesTable from "./pages/admin/InvoicesTable";
import VideosTable from "./pages/admin/VideosTable";
import InsertAuto from "./pages/admin/InsertAuto";
import InsertAutos from "./pages/admin/InsertAutos";
import ExamEditing from "./pages/admin/ExamEditing";
import PartitionEditing from "./pages/admin/PartitionEditing";
import QuestionEditing from "./pages/admin/QuestionEditing";
import Exam from "./pages/course/Exam";
import { ExamProvider } from "./context/ExamContext";
import Question from "./pages/course/exam/Question";
import ExamHome from "./pages/course/exam/ExamHome";
import ExamResult from "./pages/user/ExamResult";
import LoginData from "./pages/user/LoginData";
import LoginTokensTable from "./pages/admin/LoginTokensTable";
import LogoutTokensTable from "./pages/admin/LogoutTokensTable";
import AddUser from "./pages/admin/AddUser";
import Invoices from "./pages/user/Invoices";
import Subscriptions from "./pages/user/Subscriptions";
import VideoQuizResults from "./pages/user/VideoQuizResults";
import HmResults from "./pages/user/HmResults";
import ExamResults from "./pages/user/ExamResults";
import VideoViews from "./pages/user/VideoViews";
import QuestionInsertAuto from "./pages/admin/QuestionInsertAuto";
import ExamResultsTable from "./pages/admin/ExamResultsTable";
import HmResultsTable from "./pages/admin/HmResultsTable";
import AdminHome from "./pages/admin/AdminHome";
import DivisionsTable from "./pages/admin/DivisionsTable";
import ExamsTable from "./pages/admin/ExamsTable";
import PartitionsTable from "./pages/admin/PartitionsTable";
import QuestionsTable from "./pages/admin/QuestionsTable";
import SectionEditing from "./pages/admin/SectionEditing";
import CourseSectionsDuplicating from "./pages/admin/CourseSectionsDuplicating";
import PrepaidStore from "./pages/PrepaidStore";
import PrepaidCourseEditing from "./pages/admin/PrepaidCourseEditing";
import EditMyName from "./pages/EditMyName";
import CouponEditing from "./pages/admin/CouponEditing";
import CouponsTable from "./pages/admin/CouponsTable";
import UpdatesReleases from "./pages/admin/UpdatesReleases";
import ManageAdmins from "./pages/admin/ManageAdmins";
import CategoryEditing from "./pages/admin/CategoryEditing";
import ChangePassword from "./pages/user/ChangePassword";

const Pathes = () => {
    const location = useLocation();

    let routeKey = location.pathname.split("/");
    routeKey = routeKey[0] + "/" + routeKey[1] + "/" + routeKey[2];

    return (
        <>
            <Routes location={location} key={routeKey}>
                <Route path="/" element={<Navigate replace to={`${adminPath}`} />} />
                <Route path="/home" element={<Navigate replace to={`${adminPath}`} />} />
                <Route path={adminPath}>
                    <Route
                        path="login"
                        exact
                        element={
                            <NotAdmin>
                                <AdminLogin />
                            </NotAdmin>
                        }
                    />

                    <Route
                        path="panel"
                        element={
                            <OnlyAdmin>
                                <AdminPanel />
                            </OnlyAdmin>
                        }
                    >
                        <Route index exact element={<ManualSubscription />} />
                        <Route path="home" exact element={<AdminHome />} />
                        <Route path="updates" exact element={<UpdatesReleases />} />
                        <Route path="login_tokens" exact element={<LoginTokensTable />} />
                        <Route path="logout_tokens" exact element={<LogoutTokensTable />} />
                        <Route path="categories" exact element={<CategoryEditing />} />
                        <Route path="course" exact element={<CourseEditing />} />
                        <Route path="coupon" exact element={<CouponEditing />} />
                        <Route path="section" exact element={<SectionEditing />} />
                        <Route path="course_sections_editing_page">
                            <Route index exact element={<SelectCourseForm />} />
                            <Route path=":courseId" element={<CourseSectionEditing />} />
                        </Route>
                        <Route
                            path="course_sections_duplicating"
                            exact
                            element={<CourseSectionsDuplicating />}
                        />
                        <Route path="division" exact element={<Division />} />
                        <Route path="video" exact element={<VideoEditing />} />
                        <Route path="book" exact element={<BookEditing />} />
                        <Route path="exam" exact element={<ExamEditing />} />
                        <Route path="partition" exact element={<PartitionEditing />} />
                        <Route path="question" exact element={<QuestionEditing />} />
                        <Route path="question_insert_auto" exact element={<QuestionInsertAuto />} />
                        <Route path="manual_payment_info" exact element={<ManualPaymentInfo />} />
                        <Route path="insert_auto" exact element={<InsertAuto />} />
                        <Route path="insert_autos" exact element={<InsertAutos />} />

                        <Route
                            path="user_prepaid_courses"
                            exact
                            element={<PrepaidCourseEditing />}
                        />

                        <Route path="manual_subscription" exact element={<ManualSubscription />} />
                        <Route path="unsubscribe" exact element={<ManualUnsubscription />} />
                        <Route path="user_profile">
                            <Route index exact element={<UserProfileForm />} />
                            <Route path=":id" exact element={<UserProfile />} />
                            <Route
                                path="exam_results/:exam_result_id"
                                element={<ExamResult isAdmin={true} />}
                            />
                        </Route>
                        <Route path="add_user" exact element={<AddUser />} />
                        <Route path="users" exact element={<UsersTable />} />
                        <Route path="divisions" exact element={<DivisionsTable />} />
                        <Route path="coupons" exact element={<CouponsTable />} />
                        <Route path="courses" exact element={<CoursesTable />} />
                        <Route path="videos" exact element={<VideosTable />} />
                        <Route path="exams" exact element={<ExamsTable />} />
                        <Route path="partitions" exact element={<PartitionsTable />} />
                        <Route path="questions" exact element={<QuestionsTable />} />
                        <Route path="exam_results" exact element={<ExamResultsTable />} />
                        <Route path="hm_results" exact element={<HmResultsTable />} />
                        <Route path="subscriptions" exact element={<SubscriptionsTable />} />
                        <Route path="invoices" exact element={<InvoicesTable />} />

                        <Route path="admins" exact element={<ManageAdmins />} />
                        <Route path="logout" exact element={<AdminLogout />} />
                        <Route path="*" element={<Navigate replace to="home" />} />
                    </Route>
                    <Route index element={<Navigate replace to="login" />} />
                    <Route path="*" element={<Navigate replace to="login" />} />
                </Route>
                <Route path="404-not-found" element={<NotFound />} />
                <Route path="*" element={<Navigate replace to="/404-not-found" />} />
            </Routes>
        </>
        // </AnimatePresence>
    );
};

export default Pathes;
